<template>
  <section class="section">
    <nav class="level is-vcentered">
      <div class="level-left">
        <div class="level-item">
          <img class="level-item-img" src="@/assets/icon/clientes-level.png" alt="Clientes">
          <h1 class="title">Clientes</h1>
        </div>
      </div>
    </nav>
    <main class="inner-section">
      <div class="container">
        <div class="box">
          <div class="columns is-multiline">
            <!-- Acciones -->
            <div class="column is-8">
              <div class="columns is-multiline is-vcentered">
                <!-- Buscador -->
                <div class="column is-5">
                  <div class="field">
                    <div class="control has-icons-right">
                      <input
                        class="input is-rounded input_ibs"
                        type="text"
                        placeholder="Buscar"
                        v-model="searchText"
                        @input="searchUser"
                      >
                      <span class="icon is-right">
                        <img class="icon_search" src="@/assets/icon/search.png">
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Datos -->
            <div class="column is-12">
              <base-table
                class="is-striped-2 is-fullwidth is-hoverable"
                :columns="columnsData"
                :data="customerList"
                :loading=loading
              >
              </base-table>
            </div>
            <div class="column is-12">
              <div class="columns is-multiline is-vcentered">
                <div class="column is-12">
                  <base-pagination
                    :page="page"
                    :pages="parseInt(pages)"
                    :action="paginator"
                    :loading="loading"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
const DEFAULT_DISPLAY_SIZE = 10

export default {
  name: 'Customers',
  components: {
    BaseTable: () => import('@/components/Table.vue'),
    BasePagination: () => import('@/components/BasePagination.vue')
  },
  data () {
    return {
      page: 1,
      pages: 1,
      loading: false,
      searchText: '',
      customerList: [],
      columnsData: [
        {
          id: 'name',
          header: 'Cliente',
          class: 'column-mobile',
          accessor: (row) => `${row.name}`,
          link: true,
          type: 'link',
          route: (row) => ({ name: 'detalle-cliente', params: { customerId: row.id } })
        },
        { id: 'contact', header: 'Contacto', class: 'is-hidden-mobile' },
        { id: 'country', header: 'País', class: 'column-mobile is-hidden-mobile' },
        { id: 'services_count', header: 'Servicios', class: 'column-mobile has-text-centered' }
      ]
    }
  },
  methods: {
    ...mapActions(['getCustomers']),
    getCustomerList (page, search) {
      this.getCustomers({ page: page, limit: DEFAULT_DISPLAY_SIZE, search: search })
        .then((resp) => {
          this.pages = resp.totalPages
          this.customerList = resp.customers
          this.loading = false
        })
    },
    searchUser ({ target }) {
      this.page = 1
      this.loading = true
      if (target.value.length >= 3 || target.value.length === 0) {
        this.searchText = target.value.length === 0 ? '' : target.value
        this.$router.push({ name: 'clientes', query: { page: this.page, search: target.value } })
        this.getCustomerList(this.page, target.value)
      }
    },
    paginator (page) {
      this.loading = true
      this.page = page
      this.$router.push({ name: 'clientes', query: { page: this.page, search: this.searchText } })
      this.getCustomerList(this.page, this.searchText)
    }
  },
  beforeMount () {
    this.loading = true

    const { page, search } = this.$router.history.current.query
    this.page = (page === '' || page === undefined) ? this.page : parseInt(page, 10)
    this.searchText = search === '' ? this.searchText : search
    this.getCustomerList(this.page, this.searchText)
  }
}
</script>

<style lang="scss" scoped>
  .section {
    width: 100%;
    min-height: calc(100vh - 90px);
    padding: 0;
  }
  .input {
    font-size: 14px;
  }
  .field.is-grouped {
    display: flex;
    justify-content: flex-end;
  }
</style>
